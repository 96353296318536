import React, {
  useState, useEffect, createContext, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { axiosCustomerInstance } from '../services/axios';
import compareArrays from '../utils/compareArrays';

const SitesDataContext = createContext({});

export function SiteSettingsProvider({ children }) {
  const [activeSiteId, setActiveSiteId] = useState(localStorage.getItem('activeSite') || null);
  const [accessibleSiteInfos, setAccessibleSiteInfos] = useState([]);
  const { accessibleSiteIds, setAccessibleSiteIds } = useAuth();
  const axiosSitePrivate = useAxiosPrivate(axiosCustomerInstance);

  const fetchSite = async (siteId) => {
    try {
      const response = await axiosSitePrivate.get(`/${siteId}`);
      return {
        id: response.data.id,
        name: response.data.name,
        settings: response.data.settings,
      };
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const updateSiteSettings = async (siteIds) => {
    const results = await Promise.allSettled(
      siteIds.map((siteId) => fetchSite(siteId)),
    );
    const sites = results.filter((site) => site.status === 'fulfilled').map((site) => site.value);
    const validSiteIds = sites.map((site) => site.id);

    const localStorageActiveSite = localStorage.getItem('activeSite');
    let newActiveSiteId = localStorageActiveSite;

    // Check if the current active site is not in the list of valid site ids
    if (!validSiteIds.includes(localStorageActiveSite)) {
      newActiveSiteId = validSiteIds[0] || null;
      // Only update the localStorage and state if the active site has changed
      if (newActiveSiteId !== localStorageActiveSite) {
        localStorage.setItem('activeSite', newActiveSiteId);
        setActiveSiteId(newActiveSiteId);
      }
    } else if (!activeSiteId) {
      // This handles initialization where the React state hasn't been set yet,
      // but localStorage has a value.
      // No need to update localStorage since it already has the correct value.
      setActiveSiteId(localStorageActiveSite);
    }

    // Check if the list of accessible site IDs has changed. Update if necessary.
    if (!compareArrays(validSiteIds, accessibleSiteIds)) {
      setAccessibleSiteIds(validSiteIds);
      localStorage.setItem('accessibleSiteIds', JSON.stringify(validSiteIds));
    }
  };

  const showAppointmentDatesForSite = (siteId) => {
    const activeSiteInfo = accessibleSiteInfos.find(
      (site) => site.id === siteId,
    );
    if (!activeSiteInfo) return false;

    const smsMessageSetting = activeSiteInfo.settings.find(
      (setting) => setting.name === 'smsMessage',
    );
    return smsMessageSetting?.value.includes('{{appointment_date}}') && smsMessageSetting?.value.includes('{{appointment_time}}');
  };

  useEffect(() => {
    const updateAccessibleSiteInfos = async (siteIds) => {
      // using Promise.allSettled() ensures that the whole operation is not failed,
      // if one of the input promises fails.
      const results = await Promise.allSettled(
        siteIds.map((siteId) => fetchSite(siteId)),
      );
      const sites = results.filter((site) => site.status === 'fulfilled').map((site) => site.value);
      setAccessibleSiteInfos(sites);
    };

    updateSiteSettings(accessibleSiteIds);
    updateAccessibleSiteInfos(accessibleSiteIds);
  }, [accessibleSiteIds]);

  // Use memoization to avoid unnecessary re-renders
  const siteSettingsState = useMemo(() => ({
    activeSiteId,
    setActiveSiteId,
    accessibleSiteInfos,
    updateSiteSettings,
    showAppointmentDatesForSite,
  }), [
    activeSiteId,
    setActiveSiteId,
    accessibleSiteInfos,
  ]);

  return (
    <SitesDataContext.Provider
      value={siteSettingsState}
    >
      {children}
    </SitesDataContext.Provider>
  );
}

SiteSettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SitesDataContext;
