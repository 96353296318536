import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import Modal from '../components/Modal';

function QRCodeButton({ siteId, languageOptions }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isMobile = useMediaQuery('(max-width:425px)');
  const INTAKE_FRONTEND_URL = process.env.REACT_APP_INTAKE_FRONTEND_URL;

  // Mapping of language options to their corresponding codes
  const languageMap = {
    English: 'en',
    Arabic: 'ar',
    'French-Canadian': 'fr_CA',
  };

  // Split the language options string, map to their codes, and join back to a string
  const languagesQueryParam = languageOptions
    ? `&languages=${languageOptions.split(',').map((opt) => languageMap[opt.trim()]).join(',')}`
    : '';

  const url = `${INTAKE_FRONTEND_URL}/?siteId=${siteId}${languagesQueryParam}`;
  return (
    <>
      <Button variant="outlined" onClick={handleOpen} aria-label="Show QR Code" sx={{ ml: isMobile ? 0 : 2 }}>
        Show QR Code
      </Button>
      <Modal
        isOpen={open}
        handleClose={handleClose}
        title="QR code for intake form"
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <QRCode value={url} size={192} />
        </div>
      </Modal>
    </>
  );
}

QRCodeButton.propTypes = {
  siteId: PropTypes.string.isRequired,
  languageOptions: PropTypes.string,
};

QRCodeButton.defaultProps = {
  languageOptions: '',
};

export default QRCodeButton;
