import React, { useState } from 'react';
import jwtDecode from 'jwt-decode';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  login,
  mfaVerify,
} from '../services/auth/authServices';
import { useForm } from '../components/useForm';
import { Controls } from '../components/controls/Controls';
import Toast, { useToast } from '../components/useToast';
import useAuth from '../hooks/useAuth';
import useSiteSettings from '../hooks/useSiteSettings';
import { axiosErrorToUserMessage } from '../services/axios';
import PasswordRecovery from './PasswordRecovery';
import FormContainer from '../components/FormContainer';

const initialFieldValues = {
  username: '',
  password: '',
  mfaCode: '',
};

export default function FhxLogin() {
  const {
    setAuth,
  } = useAuth();
  const { updateSiteSettings } = useSiteSettings();
  const [signInLoading, setSignInLoading] = useState(false);
  const navigate = useNavigate();
  const [mfaStep, setMfaStep] = useState('preMfaLogin');
  const [mfaPhoneNumber, setMfaPhoneNumber] = useState('');
  const [isRecovery, setIsRecovery] = useState(false);
  const toggleView = () => {
    setIsRecovery(!isRecovery);
  };
  const {
    toastState,
    toastSetErrorNotification,
    toastOnClose,
  } = useToast();
  const {
    values,
    errors,
    setErrors,
    handleInputChange,
  } = useForm(initialFieldValues);

  const navigateToMfaSignup = (username, signUpToken) => {
    navigate('/multi-factor-signup', {
      state: { username, signUpToken },
    });
  };

  const updateAuthAndNavigate = (response) => {
    const { accessToken } = response.data;
    const decoded = jwtDecode(accessToken);
    setAuth({ accessToken });
    updateSiteSettings(decoded.siteIds);
    navigate('/home');
  };

  const handleErrors = (error) => {
    let errorMsg = {};
    const hasResponse = Boolean(error.response);
    const isInvalidVerificationCode = hasResponse && error.response.data.message === 'Invalid verification code';
    const isUnauthorizedStatus = hasResponse && (error.response.status === 403
      || error.response.status === 401);
    if (isInvalidVerificationCode) {
      toastSetErrorNotification('Invalid verification code');
    } else if (isUnauthorizedStatus) {
      errorMsg = mfaStep === 'preMfaLogin'
        ? { username: 'Invalid username/password', password: 'Invalid username/password' }
        : { mfaCode: 'Invalid MFA code' };
    } else if (hasResponse) {
      errorMsg = axiosErrorToUserMessage(error) || 'Authentication error';
    } else {
      toastSetErrorNotification(error.message);
    }
    setErrors({ ...errorMsg });
  };

  const handleLogin = async () => {
    try {
      const response = await login(values.username, values.password);
      if (response.status === 202) {
        if (response.data.info.status === 'MfaPending') {
          values.token = response.data.info.token;
          const maskedPhoneNumber = response.data.info.phoneNumber.replace(/\d(?=\d{4})/g, '*');
          setMfaPhoneNumber(maskedPhoneNumber);
          setMfaStep('verifyCode');
        } else if (response.data.info.status === 'MfaNotSet') {
          navigateToMfaSignup(values.username, response.data.info.token);
        }
      }
      if (response.status === 200) {
        // Successful login if mfa not required
        updateAuthAndNavigate(response);
      }
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleMfaVerify = async () => {
    try {
      const response = await mfaVerify(values.username, values.mfaCode, values.token);
      if (response.status === 200) {
        updateAuthAndNavigate(response);
      }
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSignInLoading(true);

    if (mfaStep === 'preMfaLogin') {
      await handleLogin();
    } else {
      await handleMfaVerify();
    }
    setSignInLoading(false);
  };

  return (
    isRecovery ? (
      <FormContainer title="Password Recovery">
        <PasswordRecovery toggleView={() => setIsRecovery(!isRecovery)} />
      </FormContainer>
    ) : (
      <FormContainer title="Sign in">
        <Box component="form" noValidate={false} onSubmit={handleSubmit} sx={{ mt: 1 }}>
          {mfaStep === 'preMfaLogin' && (
            <>
              <Controls.Input
                fullWidth
                label="Username"
                name="username"
                value={values.username}
                error={errors.username}
                onChange={handleInputChange}
                sx={{ margin: '10px 0px' }}
                data-testid="username"
              />
              <Controls.Input
                fullWidth
                label="Password"
                name="password"
                value={values.password}
                error={errors.password}
                type="password"
                onChange={handleInputChange}
                sx={{ margin: '10px 0px' }}
                data-testid="password"
              />
            </>
          )}
          {mfaStep === 'verifyCode' && (
            <>
              <Box sx={{ mb: 2 }}>
                {
                  `MFA verification. Please enter the code sent to your phone number: ${mfaPhoneNumber}`
                }
              </Box>
              <Controls.Input
                fullWidth
                label="Verification Code"
                name="mfaCode"
                value={values.mfaCode}
                error={errors.mfaCode}
                onChange={handleInputChange}
                sx={{ margin: '10px 0px' }}
              />
            </>
          )}
          <Controls.Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, width: 1 }}
            text={mfaStep === 'preMfaLogin' ? 'Sign In' : 'Verify'}
            loading={signInLoading}
            data-testid="signInButton"
          />
          <Controls.Button
            type="button"
            fullWidth
            variant="text"
            sx={{ mt: 3, mb: 2, width: 1 }}
            text="Forgot Password?"
            onClick={toggleView}
          />
        </Box>
        <Toast
          open={toastState.open}
          severity={toastState.severity}
          text={toastState.text}
          onClose={toastOnClose}
        />
      </FormContainer>
    )
  );
}
