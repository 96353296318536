import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Chip, Typography, Grid } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Button from '../components/controls/Button';
import Modal from '../components/Modal';
import { useApiClient } from '../context/ApiClientContext';
import basicServiceHandler from '../services/basicServiceHandler';
import { axiosErrorToUserMessage } from '../services/axios';

function ResetSetting(props) {
  const {
    entity, type, setting, refreshParent, setSuccessMessage, setErrorMessage,
  } = props;

  const [dialogOpen, setDialogOpen] = useState(false);

  const apiClient = useApiClient();

  const handleConfirmDelete = async () => {
    const deleteOperations = {
      customer: () => apiClient.deleteCustomerSetting(entity.id, setting.name, setting.category),
      site: () => apiClient.deleteSetting(entity.id, setting.name, setting.category),
    };

    if (setting) {
      basicServiceHandler(
        deleteOperations[type],
        () => {
          refreshParent();
          setSuccessMessage('Setting reset successfully');
          setDialogOpen(false);
        },
        (error) => {
          setErrorMessage(
            axiosErrorToUserMessage(error) || 'Error resetting setting',
          );
        },
      );
    }
  };

  return (
    <>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <Chip
            icon={<RestartAltIcon />}
            label="Reset to Default"
            onClick={() => setDialogOpen(true)}
            variant="outlined"
            color="primary"
            clickable
            style={{ cursor: 'pointer' }}
          />
        </Grid>
      </Grid>
      <Modal
        isOpen={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        title="Reset Setting"
      >
        <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
          Please confirm if you want to reset the setting:
          {' '}
          {setting.name}
          ?
        </Typography>
        <Button
          text="Cancel"
          variant="outlined"
          color="primary"
          onClick={() => setDialogOpen(false)}
          sx={{ marginRight: '1rem' }}
        />
        <Button
          text="Reset"
          variant="contained"
          color="primary"
          onClick={handleConfirmDelete}
        />
      </Modal>
    </>
  );
}

ResetSetting.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    settings: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      category: PropTypes.string,
      value: PropTypes.string,
    })),
  }).isRequired,
  type: PropTypes.string.isRequired,
  setting: PropTypes.shape({
    name: PropTypes.string,
    category: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  refreshParent: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
};

export default ResetSetting;
