import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controls } from '../../../components/controls/Controls';
import basicServiceHandler from '../../../services/basicServiceHandler';
import { convertAxiosErrorToUserFacingMessage, useApiClient } from '../../../context/ApiClientContext';
import PointClickCareModal from './PointClickCareModal';

export default function PointClickCareButton(props) {
  const {
    setSuccessMessage,
    setErrorMessage,
    integrationSettings,
    activeSiteId,
  } = props;

  const apiClient = useApiClient();

  const [tokenState, setTokenState] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    basicServiceHandler(
      () => apiClient.loginPointClickCare(activeSiteId),
      (response) => { window.location.href = response.data; },
      (error) => setErrorMessage(convertAxiosErrorToUserFacingMessage(error)),
    );
  };

  const checkIntegrationSettings = ({
    pccAccessToken,
    pccRefreshToken,
    pccAccessTokenExpiryTime,
    pccRefreshTokenExpiryTime,
  }) => {
    const currentDate = new Date().getTime();
    const accessTokenExpiry = new Date(pccAccessTokenExpiryTime).getTime();
    const refreshTokenExpiry = new Date(pccRefreshTokenExpiryTime).getTime();

    const accessTokenExpired = currentDate >= accessTokenExpiry;
    const refreshTokenExpired = currentDate >= refreshTokenExpiry;
    if (
      !pccAccessToken
      || !pccRefreshToken
      || !pccAccessTokenExpiryTime
      || !pccRefreshTokenExpiryTime
      || (accessTokenExpired || refreshTokenExpired)
    ) {
      return 'login';
    }
    return 'enabled';
  };

  useEffect(() => {
    if (integrationSettings) {
      const PCCState = checkIntegrationSettings(integrationSettings);
      setTokenState(PCCState);
    }
  }, [integrationSettings]);

  switch (tokenState) {
    case 'login':
      return (
        <Controls.Button
          sx={{ margin: '20px 0 20px 10px' }}
          variant="outlined"
          onClick={(e) => {
            handleLogin(e);
          }}
          text="Login PointClickCare"
        />
      );
    case 'enabled':
      return (
        <PointClickCareModal
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
          activeSiteId={activeSiteId}
        />
      );
    default:
      return null;
  }
}

PointClickCareButton.propTypes = {
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  integrationSettings: PropTypes.object.isRequired,
  activeSiteId: PropTypes.string.isRequired,
};
