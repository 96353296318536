import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Button,
} from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import validator from 'validator';
import useTable from '../../components/useTable';
import UnassignUserButton from './UnassignUserButton';
import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../context/ApiClientContext';
import { RolesDropdown, RolesProvider } from '../../components/RoleManager';
import usePermission from '../../hooks/usePermissions';

const headers = [
  { id: 'username', label: 'Username' },
  { id: 'roles', label: 'Roles', disableSorting: true },
  { id: 'phoneNumber', label: 'Phone Number', disableSorting: true },
  { id: 'edit', label: '', disableSorting: true },
];

export default function UserInfoTable(props) {
  const apiClient = useApiClient();
  const {
    data,
    siteId,
    siteName,
    fetchUsers,
    setSuccessMessage,
    setErrorMessage,
  } = props;

  const {
    TblContainer,
    TblHead,
    TblPagination,
    currentPageDataSorted,
  } = useTable(data, headers, { fn: (items) => items });

  const [editingRoles, setEditingRoles] = useState(null);
  const [editingUserPhoneNumber, setEditingUserPhoneNumber] = useState(null);
  const [newPhoneNumber, setNewPhoneNumber] = useState('');
  const [selectedRoles, setSelectedRoles] = useState([]);

  const {
    isAdmin,
    isCustomerAdmin,
    hasAccuroAccess,
  } = usePermission();

  const handleEditRoles = (user) => {
    setEditingRoles(user);
    setSelectedRoles(user.roles);
  };

  const handleRolesChange = (e) => {
    setSelectedRoles(e.target.value);
  };

  useEffect(() => {
  }, [selectedRoles]);

  const handleCancelRoles = () => {
    setEditingRoles(null);
  };

  const handleEditPhoneNumber = (user) => {
    setEditingUserPhoneNumber(user);
    setNewPhoneNumber(user.phoneNumber);
  };

  const handleCancelPhoneNumber = () => {
    setEditingUserPhoneNumber(null);
    setNewPhoneNumber('');
  };

  const handleSave = async (user) => {
    const userChanges = {};

    if (editingRoles === user) {
      userChanges.roles = selectedRoles;
    }

    if (editingUserPhoneNumber === user) {
      if (!newPhoneNumber || !validator.isMobilePhone(newPhoneNumber)) {
        setErrorMessage('Invalid phone number.');
        return;
      }
      userChanges.phoneNumber = newPhoneNumber;
    }
    try {
      await apiClient.updateUser(user.username, userChanges);
      setSuccessMessage('User updated successfully.');
      fetchUsers();
    } catch (error) {
      setErrorMessage(convertAxiosErrorToUserFacingMessage(error));
    }
    setEditingRoles(null);
    setEditingUserPhoneNumber(null);
    setNewPhoneNumber('');
  };

  return (
    <RolesProvider>
      <TblContainer>
        <TblHead />
        <TableBody>
          {currentPageDataSorted().map((user) => (
            <TableRow key={user.username}>
              <TableCell>{user.username}</TableCell>
              <TableCell>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <RolesDropdown
                    isEditing={editingRoles === user}
                    isAdmin={isAdmin()}
                    isCustomerAdmin={isCustomerAdmin()}
                    hasAccuroAccess={hasAccuroAccess()}
                    user={user}
                    name="roles"
                    handleInputChange={handleRolesChange}
                    selectedRoles={selectedRoles}
                  />
                  {editingRoles === user ? (
                    <>
                      <Button onClick={() => handleSave(user)}>Save</Button>
                      <Button onClick={handleCancelRoles}>Cancel</Button>
                    </>
                  ) : (
                    <Tooltip title="Edit Roles">
                      <IconButton
                        style={{ marginLeft: '10px' }}
                        onClick={() => handleEditRoles(user)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </TableCell>
              <TableCell>
                {editingUserPhoneNumber === user ? (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <PhoneInput
                      country="ca"
                      value={newPhoneNumber}
                      preferredCountries={['us', 'ca', 'ae', 'sa']}
                      onBlur={(event) => setNewPhoneNumber(event.target.value)}
                      placeholder="Enter phone number"
                      inputProps={{
                        style: { margin: '10px 0px' },
                        'data-testid': 'phoneNumber',
                      }}
                    />
                    <Button
                      onClick={() => {
                        handleSave(user);
                      }}
                    >
                      Save
                    </Button>
                    <Button onClick={handleCancelPhoneNumber}>Cancel</Button>
                  </div>
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>{user.phoneNumber || 'No phone number'}</div>
                    <Tooltip title="Edit Phone Number">
                      <IconButton
                        style={{ marginLeft: '10px' }}
                        onClick={() => handleEditPhoneNumber(user)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </TableCell>
              <TableCell>
                <UnassignUserButton
                  username={user.username}
                  siteId={siteId}
                  siteName={siteName}
                  fetchUsers={fetchUsers}
                  setSuccessMessage={setSuccessMessage}
                  setErrorMessage={setErrorMessage}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TblContainer>
      <TblPagination />
    </RolesProvider>
  );
}

UserInfoTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string.isRequired,
      phoneNumber: PropTypes.string,
      roles: PropTypes.arrayOf(PropTypes.string)
        .isRequired,
    }),
  ).isRequired,
  siteId: PropTypes.string.isRequired,
  siteName: PropTypes.string.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
};
