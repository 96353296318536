import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { convertAxiosErrorToUserFacingMessage, useApiClient } from '../../context/ApiClientContext';
import basicServiceHandler from '../../services/basicServiceHandler';

export default function ToggleButtonSetting(props) {
  const { siteId, customerId } = useParams();
  const {
    setting,
    options,
    refreshParent,
    setErrorMessage,
    setSuccessMessage,
    defaultOption,
    contextType,
  } = props;
  const apiClient = useApiClient();
  const [currentOption, setCurrentOption] = useState(setting.value);

  const handleOptionChange = (event, newOption) => {
    if (newOption === null) {
      setErrorMessage('must have one option active');
      return;
    }
    let newSetting;
    if (newOption === defaultOption.value) {
      newSetting = newOption;
    } else {
      newSetting = currentOption === '' ? [] : currentOption.split(',');
      const optionList = newSetting.includes(String(newOption))
        ? newSetting.filter((opt) => opt !== String(newOption))
        : [...newSetting, String(newOption)];
      newSetting = optionList.join(',');
    }

    const updateFunction = contextType === 'customer'
      ? () => apiClient.updateCustomer(customerId, {
        ...setting,
        value: {
          name: setting.name,
          category: setting.category,
          value: newSetting,
        },
      })
      : () => apiClient.updateSiteSetting(siteId, {
        ...setting,
        value: {
          name: setting.name,
          category: setting.category,
          value: newSetting,
        },
      });

    basicServiceHandler(
      updateFunction,
      () => {
        setCurrentOption(newSetting);
        refreshParent();
        setSuccessMessage('Successfully updated setting');
      },
      (error) => {
        setErrorMessage(convertAxiosErrorToUserFacingMessage(error));
      },
    );
  };

  useEffect(() => {
    setCurrentOption(setting.value);
  }, [setting.value]);

  return (
    <ToggleButtonGroup
      value={currentOption}
      exclusive
      onChange={handleOptionChange}
      aria-label={setting.name}
    >
      <ToggleButton
        value={defaultOption.value}
        aria-label={defaultOption.value}
        selected={setting.value === '' || setting.value === defaultOption.value}
      >
        {defaultOption.name}
      </ToggleButton>
      {options.map((option) => (
        <ToggleButton
          key={option.name}
          value={option.value}
          aria-label={option.value}
          selected={currentOption.includes(option.value)}
        >
          {option.name}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

ToggleButtonSetting.propTypes = {
  setting: PropTypes.shape({
    name: PropTypes.string,
    category: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  refreshParent: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  defaultOption: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  contextType: PropTypes.oneOf(['site', 'customer']).isRequired,
};
