import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton, Tooltip, CircularProgress, Box, Button as MuiButton,
} from '@mui/material';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import SaveIcon from '@mui/icons-material/Save';
import ReactQuill from 'react-quill';
import SpeechToTextButton from '../content/SpeechToTextButton';
import 'react-quill/dist/quill.snow.css';
import CopyReportButton from '../content/IntakeInfoTable/CopyReportButton';

export default function HtmlTextEntry({
  value, onChange, minRows, readonly, onSave, isSaving, onSuccess, onBack,
}) {
  const [editorValue, setEditorValue] = useState(value);
  const quillRef = useRef(null);
  const insertTextFromSpeech = (textFromSpeech) => {
    const quill = quillRef.current.getEditor();
    const range = quill.getSelection();
    const position = range ? range.index : quill.getLength();
    quill.insertText(position, textFromSpeech, 'user');
    quill.setSelection(position + textFromSpeech.length, 0);
    onChange(quill.root.innerHTML);
  };

  useEffect(() => {
    if (editorValue !== value) {
      setEditorValue(value);
    }
  }, [value, editorValue]);

  const handleEditorChange = (content) => {
    setEditorValue(content);
    onChange(content);
  };

  const quillModules = readonly ? {
    toolbar: false,
  } : {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }],
      [{ align: [] }],
      [{ font: [] }],
    ],
  };

  return (
    <Box sx={{ textAlign: 'center', mt: '20px', position: 'relative' }}>
      <MuiButton
        variant="text"
        sx={{
          color: 'black',
          mt: 2,
          display: 'flex',
          justifyContent: 'flex-start',
        }}
        onClick={onBack}
        startIcon={<KeyboardBackspaceRoundedIcon />}
      >
        HOME
      </MuiButton>
      <Box sx={{
        display: 'flex', justifyContent: 'flex-end', gap: '10px', mb: '10px',
      }}
      >
        {!readonly && (
          <>
            <SpeechToTextButton
              setTextRecognized={(text) => insertTextFromSpeech(text)}
              disabled={readonly}
              sx={{ position: 'relative', zIndex: 1000 }}
            />
            <Tooltip title="Save">
              <IconButton
                onClick={() => onSave(editorValue)}
                disabled={isSaving}
                sx={{ position: 'relative', zIndex: 1000 }}
              >
                {isSaving ? <CircularProgress size={24} /> : <SaveIcon />}
              </IconButton>
            </Tooltip>
          </>
        )}
        <CopyReportButton
          htmlContent={editorValue}
          onCopySuccess={() => onSuccess('Content copied to clipboard')}
        />
      </Box>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={editorValue}
        onChange={handleEditorChange}
        readOnly={readonly}
        modules={quillModules}
        style={{ height: `${minRows * 100}px`, marginBottom: '20px' }}
      />
    </Box>
  );
}

HtmlTextEntry.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  minRows: PropTypes.number.isRequired,
  readonly: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
  onSuccess: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

HtmlTextEntry.defaultProps = {
  isSaving: false,
};
