import React, { useState } from 'react';
import {
  Box,
  Typography,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { useForm } from '../components/useForm';
import { Controls } from '../components/controls/Controls';
import validatePhoneNumber from '../services/validatePhoneNumber';
import { useApiClient } from '../context/ApiClientContext';
import FormContainer from '../components/FormContainer';
import {
  sendVerificationCode,
  verifyMfaCode,
} from '../services/auth/authServices';
import Toast, { useToast } from '../components/useToast';

const initialFieldValues = {
  phoneNumber: '',
  mfa: '',
};

export default function FhxMfaSignUp() {
  const [loading, setLoading] = useState(false);
  const [mfaCode, setMfaCode] = useState('');
  const [isMfaCodeSent, setIsMfaCodeSent] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails = location.state || {};
  const [userUpdated, setUserUpdated] = useState(false);

  const {
    toastState,
    toastOnClose,
  } = useToast();

  const {
    values,
    errors,
    setErrors,
    handleInputChange,
  } = useForm(initialFieldValues);
  const apiClient = useApiClient();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    let errorMsg = {};

    if (!values.phoneNumber) {
      errorMsg.phoneNumber = 'Phone number is required';
    } else {
      const phoneNumberError = await validatePhoneNumber(values.phoneNumber, apiClient, false);
      if (phoneNumberError) {
        errorMsg.phoneNumber = phoneNumberError;
      } else {
        errorMsg.phoneNumber = '';
      }
    }

    if (!isMfaCodeSent) {
      if (errorMsg.phoneNumber) {
        setErrors(errorMsg);
        setLoading(false);
        return;
      }
      setErrors(errorMsg);
      try {
        await sendVerificationCode(
          userDetails.username,
          values.phoneNumber,
          userDetails.signUpToken,
        );
        setIsMfaCodeSent(true);
      } catch (error) {
        errorMsg.phoneNumber = 'Failed to send SMS verification code';
        setErrors(errorMsg);
      }
    } else {
      errorMsg = {};
      if (!mfaCode) {
        errorMsg.mfaCode = 'MFA code is required';
      }

      if (Object.keys(errorMsg).length > 0) {
        setErrors(errorMsg);
        setLoading(false);
        return;
      }

      try {
        const response = await verifyMfaCode(
          userDetails.username,
          values.phoneNumber,
          mfaCode,
          userDetails.signUpToken,
        );
        if (response.status === 200) {
          setUserUpdated(true);
        } else {
          errorMsg.mfaCode = 'Invalid verification code';
          setErrors(errorMsg);
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          errorMsg.mfaCode = 'Invalid verification code';
        } else {
          errorMsg.mfaCode = 'Failed to sign up for multi factor authorization';
        }
        setErrors(errorMsg);
      }
    }
    setLoading(false);
  };

  const handlePhoneChange = async (phone) => {
    const fullPhoneNumber = `+${phone}`;
    handleInputChange({ target: { name: 'phoneNumber', value: fullPhoneNumber } });
  };

  if (userUpdated) {
    return (
      <FormContainer title="Multi-factor Authentication Setup">
        <Box sx={{ mt: 1 }}>
          <Typography variant="h7">Multi-factor authorization has been enabled for your account!</Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Controls.Button
            text="Continue"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => navigate('/home')}
          />
        </Box>
      </FormContainer>
    );
  } return (
    <FormContainer title="Multi-factor Authentication Setup">
      <Typography component="p" variant="subtitle1">
        <br />
        We require your phone number to enable multi-factor authentication,
        which provides an additional layer of security for your account.
        <br />
        <br />
        We will send you a verification code to confirm the number.
      </Typography>
      <Box component="form" noValidate={false} onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <PhoneInput
          country="ca"
          value={values.phoneNumber}
          preferredCountries={['us', 'ca', 'ae', 'sa', 'ph']}
          onChange={handlePhoneChange}
          placeholder="Enter phone number"
          inputProps={{
            style: { margin: '10px 0px' },
            'data-testid': 'phoneNumber',
          }}
        />
        {errors.phoneNumber && <Typography variant="caption" color="error">{errors.phoneNumber}</Typography>}
        {isMfaCodeSent && (
          <Controls.Input
            fullWidth
            label="Code"
            name="mfaCode"
            value={mfaCode}
            error={errors.mfaCode}
            onChange={(e) => setMfaCode(e.target.value)}
            sx={{ margin: '10px 0px' }}
            data-testid="mfaCode"
          />
        )}
        <Controls.Button
          type="submit"
          text={isMfaCodeSent ? 'Verify' : 'Submit'}
          fullWidth
          variant="contained"
          color="primary"
          sx={{ margin: '20px 0px' }}
          disabled={loading}
          data-testid="submitBtn"
        />
      </Box>
      <Toast
        open={toastState.open}
        severity={toastState.severity}
        text={toastState.text}
        onClose={toastOnClose}
      />
    </FormContainer>
  );
}
