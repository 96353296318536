import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Controls } from '../controls/Controls';
import basicServiceHandler from '../../services/basicServiceHandler';
import { useApiClient } from '../../context/ApiClientContext';

// Helper function to update field border color
const updateFieldColor = (setFieldColor, color) => {
  setFieldColor({
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: color,
      borderWidth: '2px',
    },
  });
};

export default function TextFieldSetting({
  setting, setValues, refreshParent, isCreateMode, contextType,
}) {
  const apiClient = useApiClient();
  const [fieldValue, setFieldValue] = useState(setting.value);
  const [fieldColor, setFieldColor] = useState({});
  const { siteId, customerId } = useParams();

  const handleChange = (event) => {
    setFieldColor({});
    setFieldValue(event.target.value);
  };

  const handleSuccess = () => {
    refreshParent();
    updateFieldColor(setFieldColor, 'green');
  };

  const handleFailure = () => {
    updateFieldColor(setFieldColor, 'red');
  };

  const handleBlur = () => {
    // if in create mode, we want to update value back to parent component
    const updateFunction = contextType === 'customer'
      ? () => apiClient.updateCustomer(customerId, { ...setting, value: fieldValue })
      : () => apiClient.updateSiteSetting(siteId, { ...setting, value: fieldValue });

    if (isCreateMode) {
      setValues({
        name: setting.name,
        category: setting.category,
        value: fieldValue,
        siteId,
      });
    } else {
      // if in update mode, we want to be directly updating the backend with new values
      basicServiceHandler(
        updateFunction,
        handleSuccess,
        handleFailure,
      );
    }
  };

  useEffect(() => {
    setFieldValue(setting.value);
  }, [setting, siteId, contextType]);

  return (
    <Controls.Input
      label="Value"
      name="value"
      value={fieldValue ?? ''}
      onChange={handleChange}
      onBlur={handleBlur}
      sx={fieldColor}
    />
  );
}

TextFieldSetting.propTypes = {
  setting: PropTypes.shape({
    name: PropTypes.string,
    category: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  contextType: PropTypes.oneOf(['site', 'customer']).isRequired,
  setValues: PropTypes.func.isRequired,
  refreshParent: PropTypes.func,
  isCreateMode: PropTypes.bool,
};

TextFieldSetting.defaultProps = {
  refreshParent: null,
  isCreateMode: false,
};
