import useAuth from '../../hooks/useAuth';
import { axiosAuthInstance } from '../axios';

export async function login(username, password) {
  const data = {
    username,
    password,
  };
  const response = await axiosAuthInstance.post('/user/login', JSON.stringify(data));
  return response;
}

export async function mfaVerify(username, mfaVerificationCode, signUpToken) {
  const response = await axiosAuthInstance.post('/user/login/verifyMfa', { username, mfaVerificationCode }, {
    headers: {
      Authorization: `Bearer ${signUpToken}`,
    },
  });
  return response;
}

export function useRefreshTokens() {
  const { setAuth } = useAuth();

  const refresh = async () => {
    try {
      const response = await axiosAuthInstance.get('/user/refresh');
      setAuth((prev) => {
        const { accessToken } = response.data;
        return {
          ...prev,
          accessToken,
        };
      });
      return response.data;
    } catch {
      setAuth({});
      return null;
    }
  };
  return refresh;
}

export function useLogout() {
  const { setAuth, setPersist } = useAuth();
  const logout = async () => {
    await axiosAuthInstance.get('/user/logout');
    setPersist(false);
    setAuth({});
  };
  return logout;
}

export async function sendEmail(username) {
  const response = await axiosAuthInstance.post('/user/sendPasswordResetEmail', JSON.stringify({ username }));
  return response.data;
}

export async function validatePasswordResetToken(token) {
  const response = await axiosAuthInstance.post('/user/validatePasswordResetToken', undefined, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
}

export async function resetPassword(token, newPassword) {
  const data = {
    newPassword,
  };
  const response = await axiosAuthInstance.post('/user/resetPassword', JSON.stringify(data), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function sendSignUpEmail(axiosAuth, data) {
  const response = await axiosAuth.post('/user/sendSignUpEmail', JSON.stringify(data), {});
  return response.data;
}

export async function sendCreateAPIUserRequest(axiosAuth, requestBody) {
  const response = await axiosAuth.post('/user/api/signup', JSON.stringify(requestBody), {
  });
  return response.data;
}

export async function validateSignUpToken(token) {
  const data = {
    token,
  };
  const response = await axiosAuthInstance.post('/user/validateSignUpToken', JSON.stringify(data), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function sendSmsVerification(phoneNumber, token) {
  await axiosAuthInstance.post('/user/sendSmsVerification', JSON.stringify({ phoneNumber }), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function sendVerificationCode(username, phoneNumber, signUpToken) {
  const response = await axiosAuthInstance.post('/user/mfa/signup', JSON.stringify({
    username,
    phoneNumber,
  }), {
    headers: {
      Authorization: `Bearer ${signUpToken}`,
    },
  });
  return response;
}

export async function signUpUser(tokenData, userInput, signupToken) {
  const response = await axiosAuthInstance.post('/user/signup', {
    username: tokenData.username,
    first_name: userInput.firstName,
    last_name: userInput.lastName,
    password: userInput.password,
    permissions: tokenData.permissions,
    siteIds: tokenData.siteIds,
  }, {
    headers: {
      Authorization: `Bearer ${signupToken}`,
    },
  });
  return response;
}

export async function verifyMfaCode(username, phoneNumber, mfaVerificationCode, signupToken) {
  const response = await axiosAuthInstance.post('/user/mfa/signup/verify', {
    username,
    phoneNumber,
    mfaVerificationCode,
  }, {
    headers: {
      Authorization: `Bearer ${signupToken}`,
    },
  });
  return response;
}
